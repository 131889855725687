@charset "UTF-8";
@import "base.css";
@import "common.scss";
@import "_var.scss";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  padding-top: 0;
  #Header {
    h1 a {
      background-image: url(../img/header/logo_wh.png);
    }
    &:before { opacity: 1; }
  }
  #HeaderMenu {
    ul li {
      a { color: #FFFFFF; }
      &.menu07 a {
        border-color: #FFFFFF;
        span { background-image: url(../img/contents/arrow_02_01.png); }
      }
    }
  }
  &.fixed {
    #Header {
      h1 a {
        background-image: url(../img/header/logo_bk.png);
      }
    }
    #HeaderMenu {
      ul li {
        a { color: #151515; }
        &.menu07 a {
          border-color: #151515;
          span { background-image: url(../img/contents/arrow_01_01.png); }
        }
      }
    }
  }
  #MainImg {
    height: 100vh;
    .mainImgInner {
      max-width: 100%;
      padding-top: 0;
    }
    .slick {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      position: relative;
      &_item {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &_item01 {
        background-image: url(../img/contents/top/mainimg_01.jpg);
      }
      &_item02 {
        background-image: url(../img/contents/top/mainimg_02.jpg);
      }
      &_item03 {
        background-image: url(../img/contents/top/mainimg_03.jpg);
      }
      &_item04 {
        background-image: url(../img/contents/top/mainimg_04.jpg);
      }
      &_item05 {
        background-image: url(../img/contents/top/mainimg_05.jpg);
      }
      &-list, &-track {
        height: 100%;
      }
      &-dots {
        width: auto;
        right: 35px;
        bottom: 24px;
        li {
          width: 8px;
          height: 8px;
          margin: 0;
          button {
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            border-radius: 50%;
            padding: 0;
            &:before { display: none; }
          }
          & + li { margin-left: 10px; }
          &.slick-active button {
            background-color: #F05433;
          }
        }
      }
    }
    .text {
      position: absolute;
      top: 50%;
      left: 120px;
      transform: translateY(-50%);
      animation:fadeInAnime 3s forwards;
      opacity: 0;
      h2 {
        color: #FFFFFF;
        letter-spacing: 0.025em;
        text-align: left;
        margin-top: 0;
        &:nth-of-type(2) {
          font-size: 50px;
          margin-top: 24px;
          display: flex;
          align-items: flex-end;
          div { margin-right: 20px; }
        }
      }
    }
  }
  #Main {
    .contBox {
      h3 {
        margin-top: 5px;
      }
    }
    #ContBox01 {
      background-color: #F7F7F7;
      padding: 124px 0 96px;
      overflow: hidden;
      position: relative;
      .bgText {
        font-family: $font_basic01;
        font-size: 200px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1.2;
        white-space: nowrap;
        position: absolute;
        top: 21px;
        left: calc(50% + 19px);
        transform: translateX(-50%);
      }
      .innerBasic {
        max-width: 1000px;
        position: relative;
        z-index: 1;
      }
      .fadeAnimeBox {
        margin-bottom: 70px;
        h3 {
          font-size: 50px;
          font-weight: 600;
        }
      }
      .contSubBox {
        h4 {
          margin-bottom: 18px;
        }
        p {
          max-width: 915px;
        }
      }
      .btnBox {
        margin-top: 53px;
        .btnTypeBg {
          &:first-of-type .image {
            background-image: url(../img/contents/top/img_01.jpg);
          }
          &:nth-of-type(2) .image{
            background-image: url(../img/contents/top/img_02.jpg);
          }
        }
      }
    }
    #ContBox02 {
      padding: 67px 0 30px;
      .fadeAnimeBox {
        margin-bottom: 24px;
      }
      .subBox {
        .box {
          width: calc( (100% - 60px) / 3 );
          background-color: #EFEFEF;
          border-radius: 50px 0 50px 0;
          overflow: hidden;
          margin-right: 30px;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          dt {
            min-height: 70px;
            background-color: #646464;
            font-size: 20px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
            line-height: 25px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 40px;
          }
          dd {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            img { height: auto; }
          }
        }
        .box01 dd img { width: 314px; }
        .box02 dd img { width: 224px; }
        .box03 dd img { width: 177px; }
        .box04 dd img { width: 177px; }
        .box05 dd img { width: 330px; }
        .box06 dd img { width: 296px; }
      }
    }
    #ContBox03 {
      background-color: #F7F7F7;
      padding: 86px 0 101px;
    }
    #ContBox04 {
      padding: 72px 0;
      .innerBasic {
        max-width: 824px;
      }
      .subBox {
        display: flex;
        .box {
          width: 400px;
          background-color: #F7F7F7;
          color: #151515;
          text-decoration: none;
          border-radius: 50px 0 50px 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          .image {
            width: 100%;
            height: 250px;
            overflow: hidden;
            figure {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
          }
          .content {
            padding: 34px 40px 44px;
            flex: 1;
            display: flex;
            flex-direction: column;
            h4 {
              font-size: 22px;
              margin-bottom: 20px;
            }
            p {
              margin-bottom: 0;
            }
            span {
              margin-top: auto;
              margin-left: auto;
            }
          }
        }
        .box01 {
          margin-right: 24px;
          .image figure { background-image: url(../img/contents/top/img_05.jpg); }
        }
        .box02 {
          .image figure { background-image: url(../img/contents/top/img_06.jpg); }
        }
      }
    }
    #ContBox05 {
      background-color: #F7F7F7;
      padding: 61px 0 70px;
      .postTypeThumb {
        .post {
          width: 340px;
          margin-right: 20px;
        }
        .slick {
          &-track {
            display: flex;
          }
          &-slide {
            float: none;
            height: auto;
          }
        }
        .slick-arrow {
          width: 76px;
          height: 76px;
          background-color: #151515;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 23px auto;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          &:before { display: none; }
          &.slick-disabled {
            opacity: 0;
            visibility: hidden;
          }
        }
        .slick-prev {
          background-image: url(../img/contents/arrow_02_02.png);
          left: 0;
        }
        .slick-next {
          background-image: url(../img/contents/arrow_02_01.png);
          right: 100px;
        }
      }
      .innerBasic > p {
        margin: 25px 0 0;
        text-align: right;
      }
    }
  }
}

@keyframes fadeInAnime{
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


/*
エンライブンの特徴
-------------------------------------*/
#PageFeautures.pageIndex {
  background-color: #F7F7F7;
  #Main {
    .contBox {
      .boxImage {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    #ContBox01 {
      background-color: #FFFFFF;
      padding: 65px 0 73px;
      .title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 34px;
      }
      h4 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 80px;
      }
      .subBox {
        width: 100%;
        min-height: 500px;
        background-color: #F0F0F0;
        display: flex;
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        margin-bottom: 38px;
        .content {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 8.03% 5% 8.03% 8.33%;
          position: relative;
          z-index: 1;
          .num {
            font-family: $font_basic01;
            font-size: 300px;
            font-weight: bold;
            line-height: 1;
            color: #FFFFFF;
            position: absolute;
            top: -86px;
            left: -34px;
            z-index: -1;
          }
          p {
            &:last-of-type { margin-bottom: 0; }
          }
        }
        .image {
          width: 50%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        &:nth-of-type(even) {
          flex-direction: row-reverse;
          .content {
            padding: 8.03% 8.33% 8.03% 5%;
            .num {
              right: 25px;
              left: auto;
            }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .subBox01 .image { background-image: url(../img/contents/feautures/img_01_01.jpg); }
      .subBox02 .image { background-image: url(../img/contents/feautures/img_02_01.jpg); }
      .subBox03 .image { background-image: url(../img/contents/feautures/img_03_01.jpg); }
    }
    #ContBox02 {
      margin-top: 52px;
      background-color: #484848;
      padding-bottom: 100px;
      .boxImage {
        height: 400px;
        background-image: url(../img/contents/feautures/img_04_01.jpg);
        margin-bottom: 53px;
      }
      .boxTitle {
        h3 {
          font-size: 40px;
          color: #FFFFFF;
          text-align: center;
          position: relative;
          &:before {
            font-size: 60px;
            color: #FFFFFF;
            opacity: 0.1;
            margin-bottom: -10px;
          }
        }
        & + p {
          font-size: 18px;
          font-weight: 600;
          color: #FFFFFF;
          text-align: center;
          margin: 15px 0 90px;
        }
      }
      .subBox {
        display: flex;
        justify-content: space-between;
        .box {
          background-color: #FFFFFF;
          border-radius: 10px;
        }
        .box01 {
          width: 52%;
          padding: 40px 25px 43px;
          position: relative;
          h4 {
            font-size: 22px;
            font-weight: 600;
            text-align: center;
            line-height: 30px;
            margin-bottom: 22px;
          }
          .list {
            background-color: #EFEFEF;
            padding: 22px 41px 28px;
            border-radius: 10px;
            ul li span { font-weight: 600; }
          }
          &:after {
            content: '';
            width: 28px;
            height: 62px;
            background: url(../img/contents/feautures/icon_01_01.png) no-repeat left top;
            background-size: 100% auto;
            position: absolute;
            top: 50%;
            right: -44px;
            transform: translateY(-50%);
          }
        }
        .box02 {
          width: 43%;
          padding: 20px 35px;
          display: flex;
          align-items: center;
          .boxInner {
            position: relative;
          }
          img {
            width: 100%;
            height: auto;
          }
          .text {
            width: 293px;
            height: 283px;
            background: url(../img/contents/feautures/text_01.png) no-repeat left top;
            background-size: 100% auto;
            position: absolute;
            top: -60px;
            right: -76px;
          }
        }
      }
    }
    #ContBox03 {
      padding: 0 0 83px;
      .boxImage {
        height: 476px;
        background-image: url(../img/contents/feautures/img_06_01.jpg);
        margin-bottom: 30px;
        position: relative;
        span {
          white-space: nowrap;
          text-transform: capitalize;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }
      .innerBasic {
        max-width: 1000px;
      }
      h3 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      table {
        width: 100%;
        max-width: 968px;
        margin: 0 auto;
        border-collapse: unset;
        counter-reset: counter -1;
        tr {
          counter-increment: counter 1;
        }
        th, td {
          width: 21%;
          height: 62px;
          vertical-align: middle;
          text-align: center;
        }
        th {
          font-weight: 600;
          color: #FFFFFF;
          line-height: 20px;
          background-color: #646464;
          border-radius: 20px 20px 0 0;
          border-left: 6px solid #F7F7F7;
          &:first-of-type {
            opacity: 0;
            visibility: hidden;
          }
          &:nth-of-type(2) {
            background: linear-gradient(#ff550d 0%, #fd790b 53.71%, #ffb100 100%);
          }
        }
        td {
          border-top: 6px solid #F7F7F7;
          border-left: 6px solid #F7F7F7;
          padding: 11px 13px;
          background-color: #FFFFFF;
          vertical-align: middle;
          p {
            line-height: 20px;
            margin-bottom: 0;
            position: relative;
            padding-left: 1.75em;
            &:before {
              content: counter(counter) ".";
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &:first-of-type {
            width: 36%;
            background-color: #646464;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 20px;
            text-align: justify;
            border-left-width: 0;
          }
          &:nth-of-type(2) {
            .icon_maru {
              background-image: url(../img/contents/feautures/icon_02.png);
            }
          }
          .icon {
            display: block;
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 100% auto;
            margin: 0 auto;
            &_maru {
              width: 33px;
              height: 33px;
              background-image: url(../img/contents/feautures/icon_03.png);
            }
            &_batsu {
              width: 32px;
              height: 32px;
              background-image: url(../img/contents/feautures/icon_05.png);
            }
            &_sankaku {
              width: 38px;
              height: 33px;
              background-image: url(../img/contents/feautures/icon_04.png);
            }
          }
        }
      }
    }
  }
}

/*
事業紹介
-------------------------------------*/
#PageService.pageIndex {
  #Main {
    .contBox {
      .boxImage {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    #ContBox01 {
      background-color: #F7F7F7;
      padding-bottom: 100px;
      .boxImage {
        height: 390px;
        background-image: url(../img/contents/service/img_01_01.jpg);
        margin-bottom: 30px;
        .innerBasic {
          height: 100%;
          position: relative;
        }
        span {
          text-transform: capitalize;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      h3 {
        font-size: 25px;
        margin-bottom: 28px;
      }
      .scrollBox {
        margin-top: 50px;
      }
      .subBox {
        display: flex;
        .box {
          width: calc( (100% - 153px) / 6 );
          display: flex;
          flex-direction: column;
          margin-right: 23px;
          position: relative;
          &:last-of-type {
            margin-right: 0;
            &:before, &:after {
              display: none;
            }
          }
          p {
            font-weight: 600;
            margin-bottom: 0;
          }
          .phase {
            background-color: #98887C;
            color: #FFFFFF;
            padding: 7px 16px 29px;
            border-radius: 10px;
            margin-bottom: 14px;
            height: 100%;
            min-height: 185px;
            flex: 1;
            position: relative;
            z-index: 1;
            .num {
              font-family: $font_basic01;
              font-size: 40px;
              font-weight: bold;
              text-align: center;
              line-height: 1;
              opacity: 0.6;
              display: block;
              margin-bottom: 7px;
            }
            h4 {
              background-color: #FFFFFF;
              font-size: 18px;
              font-weight: 600;
              color: #98887C;
              text-align: center;
              padding: 1px;
              border-radius: 15px;
              margin-bottom: 9px;
            }
            p {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .service {
            background-color: #FFFFFF;
            border: 1px solid #767676;
            border-radius: 10px;
            padding: 18px 19px 23px;
            height: 100%;
            min-height: 0;
            flex: 2;
            ul li {
              font-size: 12px;
              line-height: 16px;
              padding-left: 9px;
              &:before {
                background-color: #BCBCBC;
                top: 5px;
              }
              & + li { margin-top: 5px; }
            }
          }
          &:before {
            content: '';
            width: 2px;
            height: 100%;
            background-image: linear-gradient(to bottom, #B7B7B7, #B7B7B7 5px, transparent 5px);
            background-size: 2px 10px;
            background-position: center top;
            background-repeat: repeat-y;
            position: absolute;
            top: 0;
            right: -12px;
          }
          &:after {
            content: '';
            width: 25px;
            height: 105px;
            background: url(../img/contents/service/arrow_01.png) no-repeat left top;
            background-size: 100% auto;
            position: absolute;
            top: 50px;
            right: -20px;
          }
        }
        .box01 {
          width: 27px;
          margin-right: 11px;
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 !important;
            p {
              writing-mode: vertical-rl;
              font-size: 14px !important;
              line-height: 20px !important;
            }
          }
          .service {
            background-color: #767676;
            color: #FFFFFF;
          }
          &:before, &:after {
            display: none;
          }
        }
      }
    }
    #ContBox02 {
      padding-bottom: 80px;
      .contSubBox {
        display: flex;
        margin: 0 auto;
        .image {
          width: 59.86111111111111%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50px 0 0 50px;
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 120px;
          padding-right: 76px;
        }
        &:nth-of-type(even) {
          flex-direction: row-reverse;
          .image {
            width: 40.13888888888889%;
            border-radius: 0 50px 50px 0;
          }
          .content {
            padding-left: 54px;
            padding-right: 120px;
          }
        }
      }
      .contSubBox01 {
        min-height: 700px;
        margin-bottom: 88px;
        justify-content: right;
        .image {
          background-image: url(../img/contents/service/img_02_01.jpg);
          border-top-left-radius: 0;
        }
        .content {
          max-width: 578px;
          .fadeAnimeBox {
            margin-bottom: 60px;
            .svgBox {
              margin-bottom: 5px;
            }
          }
          p:last-of-type {
            text-align: right;
          }
        }
      }
      .contSubBox02 {
        min-height: 570px;
        justify-content: left;
        .content {
          max-width: 862px;
          .title {
            margin-bottom: 30px;
            h4 {
              font-size: 25px;
              align-items: flex-start;
              &:before { margin-bottom: 13px; }
            }
          }
          p:last-of-type {
            margin-bottom: 0;
          }
        }
        .image {
          background-image: url(../img/contents/service/img_03_01.jpg);
        }
      }
    }
  }
}

/*
新卒採用
-------------------------------------*/
#PageService.pageNew {
  background-color: #F7F7F7;
  #MainImg {
    .mainImgInner {
      .text .svgBox { margin-bottom: 10px; }
    }
  }
  #Main {
    #ContBox01 {
      height: 723px;
      position: relative;
      .boxImage {
        width: 100%;
        height: 100%;
        background: url(../img/contents/service/recruitment/img_01.jpg) no-repeat center center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .innerBasic {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .text {
        max-width: 456px;
        background-color: #FFFFFF;
        padding: 48px 45px;
        h3 {
          font-size: 35px;
          line-height: 52px;
          margin-bottom: 23px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    #ContBox02 {
      background-color: #151515;
      padding: 48px 0 26px;
      position: relative;
      &:before {
        content: '';
        border-style: solid;
        border-width: 73px 50vw 0 50vw;
        border-color: #151515 transparent transparent transparent;
        position: absolute;
        left: 0;
        bottom: -73px;
        z-index: 1;
      }
      h3 {
        font-size: 25px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 26px;
      }
      .subBox {
        max-width: 923px;
        background-color: #FFFFFF;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
        .content {
          flex: 1;
          padding: 46px 28px 46px 41px;
          ul li {
            font-size: 18px;
            line-height: 20px;
            text-align: justify;
            padding-left: 56px;
            position: relative;
            & + li {
              margin-top: 25px;
            }
            &:before {
              content: '';
              width: 41px;
              height: 34px;
              background: url(../img/contents/service/recruitment/icon_01.png) no-repeat left top;
              background-size: 100% auto;
              position: absolute;
              top: -7px;
              left: 0;
            }
          }
        }
        .image {
          width: 44.63705308775731%;
          background: url(../img/contents/service/recruitment/img_02_01.jpg) no-repeat center center;
          background-size: cover;
        }
      }
    }
    #ContBox03 {
      background-color: #838383;
      padding-top: 100px;
      padding-bottom: 80px;
      .innerBasic {
        max-width: 1000px;
      }
      .boxTitle {
        margin-bottom: 40px;
        h3 {
          font-size: 25px;
          color: #FFFFFF;
          text-align: center;
          &:before {
            font-size: 130px;
            margin-bottom: -25px;
          }
        }
      }
      .leadTxt {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 49px;
      }
      .flowBox {
        .box {
          min-height: 215px;
          display: flex;
          background-color: #FFFFFF;
          border-radius: 20px 0 20px 0;
          position: relative;
          &:before {
            content: '';
            width: 49px;
            height: 49px;
            background: #838383 url(../img/contents/arrow_02_04.png) no-repeat center;
            background-size: 16px auto;
            border-radius: 50%;
            position: absolute;
            top: -33px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
          }
          & + .box {
            margin-top: 15px;
            &:before {
              opacity: 1;
              visibility: visible;
            }
          }
          .image {
            width: 44.4%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: 20px;
          }
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            padding: 20px 40px 20px 36px;
            .title {
              font-size: 18px;
              font-weight: 600;
              display: flex;
              align-items: center;
              margin-bottom: 11px;
              .label {
                font-family: $font_basic01;
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;
                text-transform: capitalize;
                background-color: #151515;
                padding: 1px 34px 2px;
                border: 2px solid #FFFFFF;
                border-radius: 20px;
                margin-right: 15px;
              }
            }
            p {
              line-height: 25px;
              margin-bottom: 0;
              &.btnTypeText { margin-top: 10px; }
            }
          }
        }
        .box01 .image { background-image: url(../img/contents/service/recruitment/img_03.jpg); }
        .box02 .image { background-image: url(../img/contents/service/recruitment/img_04.jpg); }
        .box03 .image { background-image: url(../img/contents/service/recruitment/img_05.jpg); }
        .box04 .image { background-image: url(../img/contents/service/recruitment/img_06.jpg); }
        .box05 .image { background-image: url(../img/contents/service/recruitment/img_07.jpg); }
        .box06 .image { background-image: url(../img/contents/service/recruitment/img_08.jpg); }
        .box07 .image { background-image: url(../img/contents/service/recruitment/img_09.jpg); }
        .box08 .image { background-image: url(../img/contents/service/recruitment/img_10.jpg); }
      }
    }
    #ContBox04 {
      padding: 66px 0 120px;
      .innerBasic {
        max-width: 1000px;
      }
      p:last-of-type {
        margin-bottom: 0 !important;
      }
      h3 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 28px;
      }
      .qaBox {
        .qa {
          & + .qa { margin-top: 30px; }
          .click {
            background-color: #151515;
            display: flex;
            p {
              flex: 1;
              color: #FFFFFF;
              padding: 15px 0 15px 74px;
              position: relative;
              &:before {
                content: 'Q';
                font-family: $font_basic01;
                font-size: 30px;
                font-weight: bold;
                position: absolute;
                top: 14px;
                left: 27px;
              }
            }
            .arrow {
              width: 60px;
              background: #F05433 url(../img/contents/arrow_02_04.png) no-repeat center;
              background-size: 16px auto;
            }
            &.active .arrow {
              background-image: url(../img/contents/arrow_02_03.png);
            }
          }
          .showBox {
            background-color: #FFFFFF;
            padding: 24px 96px 33px 74px;
            position: relative;
            &:before {
              content: 'A';
              font-family: $font_basic01;
              font-size: 30px;
              font-weight: bold;
              color: #F05433;
              position: absolute;
              top: 14px;
              left: 27px;
            }
            p {
              line-height: 25px;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
    #PopBox {
      width: 100%;
      height: 100vh;
      background-color: rgba(21,21,21,0.65);
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 70;
      &.open {
        display: block;
      }
      .innerBasic {
        height: 100%;
        padding: 90px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .box {
        width: 83.33333333333333%;
        height: 100%;
        background-color: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 10px;
        position: relative;
        display: none;
        .boxWrapper {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;
        }
        .boxInner {
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          scrollbar-width: thin;
        }
        .boxContent {
          height: auto;
          padding: 45px 50px;
        }
        .image {
          img {
            width: 100%;
            height: auto;
            border-radius: 20px;
          }
        }
        .content {
          margin-top: 40px;
          .title {
            font-size: 25px;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-bottom: 19px;
            .label {
              font-family: $font_basic01;
              font-size: 20px;
              font-weight: bold;
              color: #FFFFFF;
              text-transform: capitalize;
              background-color: #151515;
              padding: 0 31px;
              border: 2px solid #FFFFFF;
              border-radius: 20px;
              margin-right: 11px;
            }
          }
          p:last-of-type {
            margin-bottom: 0;
          }
        }
        .popBtnClose {
          width: 47px;
          height: 47px;
          background: #151515 url(../img/contents/icon_01.png) no-repeat center;
          background-size: 17px auto;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          top: -28px;
          right: -24px;
        }
        &.is_visible {
          display: block;
        }
      }
      .popBtn {
        position: absolute;
      }
      .popBtnPrev, .popBtnNext {
        color: #FFFFFF;
        text-decoration: none;
        top: 50%;
        transform: translateY(-50%);
        span {
          display: block;
          &.arrow {
            width: 70px;
            height: 70px;
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 100% auto;
          }
          &.text {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-top: 9px;
          }
        }
      }
      .popBtnPrev {
        left: 0;
        .arrow { background-image: url(../img/contents/arrow_04_02.png); }
      }
      .popBtnNext {
        right: 0;
        .arrow { background-image: url(../img/contents/arrow_04_01.png); }
      }
    }
  }
}

/*
実績紹介
-------------------------------------*/
#PageAchivements.pageIndex {
  background-color: #F7F7F7;
  #Main {
    #ContBox01 {
      padding-bottom: 107px;
    }
  }
}

/*
実績紹介　下層共通
-------------------------------------*/
#PageAchivements.lower {
  #Main {
    .contBox {
      .innerBasic {
        max-width: 920px;
      }
    }
    #ContBox01 {
      height: 590px;
      position: relative;
      .boxImage {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .innerBasic {
        max-width: 1200px;
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
      }
      .text {
        h3 {
          span { display: inline-block; }
          & + h3 { margin-top: 13px; }
          &:first-of-type {
            position: relative;
            &:before {
              font-family: $font_basic02;
              font-size: 20px;
              font-weight: bold;
              color: #FFFFFF;
              background: linear-gradient(#ff550d 0%, #fd790b 53.71%, #ffb100 100%);
              width: 46px;
              height: 46px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              position: absolute;
              top: -31px;
              left: -23px;
            }
          }
        }
      }
    }
    #ContBox02 {
      padding: 53px 0 66px;
      .logo {
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin: 0 auto;
      }
      table {
        max-width: 675px;
        margin: 0 auto;
        th {
          width: 160px;
        }
      }
    }
    #ContBox03 {
      background-color: #EAE3DE;
      padding: 39px 0 64px;
      h3 {
        font-size: 20px;
        margin-bottom: 18px;
      }
      .subBox {
        display: flex;
        flex-wrap: wrap;
        .box {
          width: calc( (100% - 60px) / 4);
          background-color: #FFFFFF;
          border-radius: 50px 0 50px 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          & + .box { margin-left: 20px; }
          dt {
            background-color: #646464;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 20px;
            text-align: center;
            padding: 5px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          dd {
            padding: 21px 18px 25px;
            p {
              font-weight: 600;
              margin-bottom: 0;
              span {
                font-family: $font_basic02;
                font-weight: bold;
                display: inline-block;
              }
              &.num {
                font-size: 30px;
                line-height: 50px;
                color: #F09933;
                position: relative;
                z-index: 1;
                span {
                  font-size: 50px;
                  position: relative;
                  z-index: 1;
                }
                &:after {
                  content: '';
                  width: 63px;
                  height: 44px;
                  background: url(../img/contents/achivements/icon_01.png) no-repeat left top;
                  background-size: 100% auto;
                  position: absolute;
                  right: 0;
                  bottom: 8px;
                  z-index: -1;
                }
              }
              &.numPeo {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: #FFFFFF;
                background: linear-gradient(to left, #ff8126 0%, #ff8e30 32.73%, #ff9233 42.45%, #ffa945 100%);
                padding: 2px 0 1px;
                border-radius: 20px;
                margin-top: 12px;
                span {
                  font-size: 25px;
                  &:nth-of-type(2) {
                    position: relative;
                    margin-left: 19px;
                    &:before {
                      content: '';
                      width: 10px;
                      height: 17px;
                      background: url(../img/contents/achivements/icon_02.png) no-repeat left top;
                      background-size: 100% auto;
                      position: absolute;
                      top: 50%;
                      left: -15px;
                      transform: translateY(-50%);
                    }
                  }
                }
              }
            }
          }
        }
        > p {
          width: 100%;
          text-align: right;
          margin: 8px 0 0;
          &:before { display: none; }
        }
      }
    }
    #ContBox04 {
      padding: 55px 0;
      .contSubBox {
        h3 {
          font-size: 20px;
          margin-bottom: 23px;
        }
      }
      .contSubBox01 {
        margin-bottom: 33px;
        .subBox {
          padding: 27px 28px;
          border: 1px solid #D6D6D6;
          ul {
            width: 50%;
            & + ul {
              padding-left: 15px;
            }
            li {
              line-height: 18px;
              & + li { margin-top: 15px; }
            }
          }
        }
      }
      .contSubBox02 {
        .subBox {
          align-items: center;
          position: relative;
          p {
            margin-bottom: 0;
          }
          .box01 {
            background-color: #EBEBEB;
            padding: 26px 23px 26px 19px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            p {
              margin-bottom: 0;
            }
            dt {
              background-color: #151515;
              font-weight: 600;
              color: #FFFFFF;
              padding: 15px 23px;
              margin-right: 12px;
            }
          }
          .box02 {
            background-color: #F09933;
            font-weight: 600;
            color: #FFFFFF;
            padding: 9px 5px 9px 16px;
            margin-right: 25px;
            position: relative;
            &:after {
              content: '';
              width: 39px;
              height: 83px;
              background: url(../img/contents/achivements/icon_03_01.png) no-repeat left top;
              background-size: 100% auto;
              position: absolute;
              top: 50%;
              right: -25px;
              transform: translateY(-50%);
              z-index: -1;
            }
          }
          .box03 {
            background-color: #EAE3DE;
            padding: 23px 49px;
            border-radius: 10px;
            position: relative;
            p {
              font-size: 18px;
              font-weight: 600;
              line-height: 25px;
            }
          }
          .box04 {
            width: 166px;
            height: 63px;
            background: url(../img/contents/achivements/icon_04_01.png) no-repeat left top;
            background-size: 100% auto;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    #ContBox05 {
      background-color: #F7F7F7;
      padding: 41px 0 53px;
      .title {
        margin-bottom: 35px;
        h3 {
          font-size: 20px;
          text-align: center;
          display: flex;
          flex-direction: column;
          &:before {
            font-size: 80px;
            color: #E8E8E8;
            text-transform: capitalize;
            margin-bottom: -24px;
          }
        }
      }
      .contSubBox {
        & + .contSubBox {
          margin-top: 40px;
        }
        h4 {
          font-size: 20px;
        }
        .subBox {
          &:after {
            content: '';
            display: block;
            clear: both;
          }
          .photoLeft {
            float: left;
            margin-right: 24px;
          }
          .photoRight {
            float: right;
            margin-left: 24px;
          }
          img {
            display: block;
            width: 380px;
            height: auto;
            margin-bottom: 20px;
            &:nth-of-type(2) { margin-bottom: 0; }
          }
          p {
            line-height: 25px;
            margin-bottom: 25px;
            &:last-of-type { margin-bottom: 0; }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    #ContBox06 {
      padding: 51px 0;
      .innerBasic {
        max-width: 890px;
      }
      h3 {
        font-size: 20px;
        margin-bottom: 17px;
      }
      table {
        th { width: 155px; }
        td {
          a {
            color: #151515;
            text-decoration: none;
          }
        }
      }
    }
  }
  #BackBox {
    height: 147px;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 18px;
      font-weight: 600;
      color: #151515;
      text-decoration: none;
      padding-left: 70px;
      position: relative;
      &:before {
        content: '';
        width: 59px;
        height: 59px;
        background: #151515 url(../img/contents/arrow_02_02.png) no-repeat center center;
        background-size: 15px auto;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

/*
株式会社トップ精工
-------------------------------------*/
#PageAchivements.page01.lower {
  #Main {
    #ContBox01 {
      .boxImage {
        background-image: url(../img/contents/achivements/lower01_img_01.jpg);
      }
      .text {
        h3:first-of-type:before {
          content: '01';
        }
      }
    }
    #ContBox02 {
      .logo {
        width: 422px;
        height: 67px;
        background-image: url(../img/contents/logo_01.png);
        margin-bottom: 58px;
      }
    }
    #ContBox03 {
      .subBox {
        .box03 {
          dd {
            p.num {
              text-shadow: 3px 3px 0 #FFF, -3px 3px 0 #FFF, -3px -3px 0 #FFF, 3px -3px 0 #FFF;
              &:after { right: -11px; }
            }
          }
        }
      }
    }
  }
}

/*
株式会社日本プロパティシステムズ
-------------------------------------*/
#PageAchivements.page02.lower {
  #Main {
    #ContBox01 {
      .boxImage {
        background-image: url(../img/contents/achivements/lower02_img_01.jpg);
      }
      .text {
        h3:first-of-type:before {
          content: '02';
        }
      }
    }
    #ContBox02 {
      .logo {
        width: 444px;
        height: 85px;
        background-image: url(../img/contents/logo_02.png);
        margin-bottom: 43px;
      }
    }
    #ContBox03 {
      .subBox {
        .box {
          width: calc( (100% - 40px) / 3 );
          dt {
            min-height: 50px;
          }
          dd p.num {
            max-width: 186px;
            margin: 0 auto;
          }
        }
      }
    }
    #ContBox04 {
      .contSubBox02 {
        .subBox {
          .box01 {
            padding: 20px 16px;
            dt {
              padding: 8px 5px 8px 8px;
              margin-right: 6px;
            }
          }
          .box02 {
            padding: 9px 6px 9px 19px
          }
          .box03 {
            padding: 27px 49px 28px;
          }
          .box04 {
            bottom: 9px;
          }
        }
      }
    }
  }
}

/*
株式会社八興
-------------------------------------*/
#PageAchivements.page03.lower {
  #Main {
    #ContBox01 {
      .boxImage {
        background-image: url(../img/contents/achivements/lower03_img_01.jpg);
      }
      .innerBasic {
        justify-content: left;
        padding-left: 23px;
      }
      .text {
        h3:first-of-type:before {
          content: '03';
        }
      }
    }
    #ContBox02 {
      .logo {
        width: 383px;
        height: 82px;
        background-image: url(../img/contents/logo_03.png);
        margin-bottom: 40px;
      }
    }
    #ContBox04 {
      .subBox {
        .box01 {
          padding: 33px 57px 32px 19px;
          dt {
            padding: 25px 16px;
          }
        }
        .box02 {
          padding: 5px 12px 5px 16px;
        }
        .box03 {
          padding: 32px 37px;
        }
        .box04 {
          width: 133px;
          background-image: url(../img/contents/achivements/icon_04_02.png);
          bottom: -14px;
        }
      }
    }
    #ContBox05 {
      .contSubBox01 {
        .floatSpace {
          float: right;
          padding-top: 29.89130434782609%;
        }
        img {
          clear: both;
        }
      }
    }
  }
}

/*
採用コラム
-------------------------------------*/
#PageColumn {
  background-color: #F7F7F7;
  #Main {
    #ContBox01 {
      padding: 77px 0 79px;
      .innerBasic {
        max-width: 1060px;
      }
      .cateNav {
        margin-bottom: 52px;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          li {
            margin-right: 20px;
            a {
              display: block;
              color: #838383;
              text-decoration: none;
              padding: 3px 20px 4px 21px;
              border: 1px solid #838383;
              border-radius: 20px;
            }
            &:last-of-type {
              margin-right: 0;
            }
            &.current a {
              background-color: #151515;
              color: #FFFFFF;
              border-color: #151515;
            }
          }
        }
      }
      .postTypeThumb {
        display: flex;
        flex-wrap: wrap;
        .post {
          width: calc( (100% - 40px) / 3 );
          margin-right: 20px;
          margin-bottom: 20px;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
      .btnTypeMore {
        display: flex;
        flex-direction: column;
        width: 60px;
        font-family: $font_basic02;
        font-size: 18px;
        font-weight: bold;
        color: #151515;
        text-decoration: none;
        margin: 56px auto 0;
        .text {
          text-align: center;
          margin-bottom: 2px;
        }
        .arrow {
          width: 59px;
          height: 59px;
          background: #151515 url(../img/contents/arrow_02_04.png) no-repeat center center;
          background-size: 15px auto;
          border-radius: 50%;
        }
      }
    }
  }
}

/*
カテゴリアーカイブ
-------------------------------------*/
#PageColumn.pageCategory {
  #Main {
    #ContBox01 {
      .cateNav {
        margin-bottom: 40px;
      }
      h3 {
        text-align: center;
        margin-bottom: 39px;
      }
    }
  }
}

/*
記事詳細
-------------------------------------*/
#PageColumn.pageEntry {
  #Main {
    #ContBox01 {
      padding-bottom: 108px;
      .innerBasic {
        max-width: 1000px;
      }
      .cateNav {
        margin-bottom: 65px;
      }
      .subBox {
        background-color: #FFFFFF;
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        .entryTitle {
          background-color: #3E3E3E;
          font-size: 25px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 35px;
          text-align: justify;
          padding: 37px 41px;
        }
        .thumbnail {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          padding-top: 49%;
        }
        .entryBox {
          padding: 64px 41px 52px;
          .date {
            display: block;
            font-family: $font_basic02 !important;
            font-weight: 500 !important;
            color: #A8A8A8 !important;
            margin-bottom: 30px;
          }
        }
      }
      .pageNav {
        margin-top: 28px;
        ul {
          width: 386px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          li {
            a {
              display: block;
              color: #151515;
            }
            &:not(.home) a {
              width: 59px;
              height: 59px;
              text-decoration: none;
              background-color: #151515;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 15px auto;
              border-radius: 50%;
            }
            &.prev a {
              background-image: url(../img/contents/arrow_02_02.png);
            }
            &.next a {
              background-image: url(../img/contents/arrow_02_01.png);
            }
            &.none a {
              background-color: #CBCBCB;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

/*
エンライブンについて
-------------------------------------*/
#PageAbout.pageIndex {
  background-color: #F7F7F7;
  #Main {
    .contBox {
      .innerBasic {
        max-width: 1000px;
      }
      .nameBox {
        background-color: #FFFFFF;
        position: absolute;
        right: 0;
        bottom: 0;
        .position {
          display: block;
          margin-bottom: 3px;
        }
        p {
          font-size: 25px;
          margin-bottom: 0;
          .en {
            display: inline-block;
            font-family: $font_basic01;
            font-size: 20px;
            color: #B5B5B5;
            margin-left: 25px;
            position: relative;
            &:before {
              content: '';
              width: 15px;
              height: 22px;
              background: url(../img/contents/about/line_01.png) no-repeat left top;
              background-size: 100% auto;
              position: absolute;
              top: 50%;
              left: -20px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    #ContBox01 {
      height: 616px;
      background: url(../img/contents/about/img_01.jpg) no-repeat center top;
      background-size: cover;
      .innerBasic {
        height: 100%;
        position: relative;
      }
      .nameBox {
        padding: 18px 14px 18px 34px;
      }
    }
    #ContBox02 {
      background-color: #FFFFFF;
      padding: 17px 0 174px;
      .contSubBox {
        p {
          line-height: 25px;
          margin-bottom: 25px;
          &:last-of-type { margin-bottom: 0; }
        }
      }
      .contSubBox01 {
        margin-bottom: 103px;
        .title {
          margin-bottom: 43px;
          h3 {
            font-size: 25px;
            &:before {
              font-size: 130px;
              color: #F5F5F5;
              text-align: left;
              margin-bottom: -43px;
            }
          }
        }
        h4 {
          line-height: 50px;
          margin-bottom: 40px;
        }
      }
      .contSubBox02 {
        .title {
          .en {
            font-family: $font_basic01;
            font-weight: bold;
            font-size: 100px;
            color: #F5F5F5;
            line-height: 1;
            display: block;
            margin-bottom: -20px;
          }
        }
        h4 {
          font-size: 28px;
          margin-bottom: 51px;
        }
        .subBox {
          display: flex;
          > div {
            width: 50%;
          }
          .photo {
            height: 535px;
            background: url(../img/contents/about/img_02.jpg) no-repeat right center;
            background-size: cover;
            position: relative;
            .nameBox {
              padding: 20px 31px;
            }
          }
          .content {
            padding-left: 51px;
          }
        }
      }
    }
    #ContBox03 {
      padding: 104px 0 102px;
      .title {
        text-align: center;
        margin-bottom: 32px;
        .en {
          margin-bottom: 3px;
        }
        h3 {
          font-size: 25px;
          text-align: center;
        }
      }
      .subBox {
        max-width: 770px;
        margin: 0 auto;
        table {
          th, td {
            border-bottom-color: #F7F7F7;
          }
          th {
            width: 126px;
            border-right-color: #F7F7F7;
          }
          td {
            background-color: #FFFFFF;
            line-height: 30px;
            padding: 15px 22px;
          }
        }
      }
    }
  }
}

/*
お問い合わせ
-------------------------------------*/
#PageContact {
  background-color: #F5F5F5;
  #Main #ContBox01 {
    background-color: #FFFFFF;
    .form_table {
      table-layout: fixed;
      tr {
        &:last-of-type td { margin-bottom: 0 !important; }
      }
      th, td {
        display: block;
        width: 100%;
        line-height: 30px;
      }
      th {
        font-weight: 400;
      }
    }
    .form_btn {
      display: block;
      width: 256px;
      height: 56px;
      color: #FFFFFF;
      background-color: #151515;
      border-radius: 3px;
      margin: 41px auto 0;
      span {
        background: url(../img/contents/arrow_02_01.png) no-repeat right 1px center;
        background-size: 16px auto;
        padding-right: 28px;
      }
    }
  }
}

#PageContact.pageIndex {
  #Main {
    #ContBox01 {
      padding: 40px 0 48px;
      p {
        text-align: center;
        margin-bottom: 39px;
      }
      .form {
        max-width: 567px;
        margin: 0 auto;
        &_table {
          width: 100%;
          th {
            padding-left: 58px;
            margin-bottom: 6px;
            position: relative;
            &:before {
              content: '必須';
              font-size: 14px;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 1.5;
              background-color: #F05433;
              border-radius: 3px;
              padding: 0 11px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
          td {
            margin-bottom: 20px;
          }
        }
        input, textarea {
          display: block;
          width: 100%;
          padding: 15px 18px;
          border: 1px solid #D5D5D5;
          border-radius: 5px;
        }
        textarea {
          height: 245px;
          resize: vertical;
        }
      }
    }
  }
}

/*
お問い合わせ（確認画面）
-------------------------------------*/
#PageContact.pageConfirm {
  #Main {
    #ContBox01 {
      padding: 50px 0 62px;
      h3 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 39px;
      }
      > p {
        margin: 0 auto;
        &:first-of-type {
          max-width: 646px;
        }
        &:last-of-type {
          max-width: 679px;
        }
        &.error_text {
          text-align: center;
          margin-bottom: 30px;
        }
        &.error_messe {
          text-align: center;
        }
        &.error_btn {
          margin: 30px auto 0;
        }
      }
      .tableBox {
        border-top: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
        padding: 48px 0;
        margin: 41px 0;
      }
      .form {
        margin-bottom: 30px;
        &_table {
          width: 100%;
          max-width: 717px;
          margin: 0 auto;
          th {
            color: #FFFFFF;
            background-color: #838383;
            padding: 2px 17px 3px;
            border-radius: 6px;
            margin-bottom: 7px;
          }
          td {
            margin-bottom: 22px;
          }
        }
        &_return {
          text-align: center;
          margin: 20px 0 0;
          a {
            display: inline-block;
            color: #151515;
            text-decoration: none;
            background: url(../img/contents/arrow_01_02.png) no-repeat left center;
            background-size: 16px auto;
            padding-left: 20px;
          }
          + p {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

/*
お問い合わせ（完了画面）
-------------------------------------*/
#PageContact.pageThanks {
  #Container {
    background-color: #FFFFFF;
  }
  #Main {
    #ContBox01 {
      padding: 100px 0 57px;
      h3 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 37px;
      }
      p {
        &:not(.home) {
          width: 608px;
          margin: 0 auto 69px;
        }
        &.home {
          display: flex;
          justify-content: center;
          text-align: center;
          margin: 0;
          a {
            display: flex;
            align-items: center;
            color: #151515;
            text-decoration: none;
            .arrow {
              width: 59px;
              height: 59px;
              background: #151515 url(../img/contents/arrow_02_02.png) no-repeat center;
              background-size: 15px auto;
              border-radius: 50%;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}

/*
個人情報保護方針
-------------------------------------*/
#PagePolicy.pageIndex {
  background-color: #F7F7F7;
  #Main {
    #ContBox01 {
      background-color: #FFFFFF;
      padding: 100px 0;
      .innerBasic {
        max-width: 1000px;
      }
      h3 {
        font-size: 25px;
        margin-bottom: 30px;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
      .subBox {
        margin-top: 30px;
        h4 {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .subBox02 {
        ul {
          margin-left: 1em;
        }
      }
      .subBox06 {
        margin-bottom: 34px;
        .box {
          display: inline-block;
          padding: 18px 20px;
          border: 1px solid #151515;
          margin-top: 20px;
        }
      }
    }
  }
}

/*
404
-------------------------------------*/
#Page404.pageIndex {
  #Main {
    .contBox {
      padding: 100px 0;
      h3 {
        text-align: center;
      }
      p {
        text-align: center;
      }
      .home {
        display: flex;
        justify-content: center;
        text-align: center;
        a {
          display: flex;
          align-items: center;
          color: #151515;
          text-decoration: none;
          .arrow {
            width: 59px;
            height: 59px;
            background: #151515 url(../img/contents/arrow_02_02.png) no-repeat center;
            background-size: 15px auto;
            border-radius: 50%;
            margin-right: 12px;
          }
        }
      }
    }
  }
}


@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Main {
      #ContBox04 {
        .subBox .box {
          .image figure {
            transform: scale(1);
            transition: 0.5s ease;
          }
          &:hover {
            .image figure { transform: scale(1.12); }
            .content span {
              background-position: right center;
              color: #f09933;
            }
          }
        }
      }
      #ContBox05 {
        .postTypeThumb {
          .post {
            opacity: 0.4
          }
          .slick {
            &-current {
              opacity: 1;
              & + .slick-slide {
                opacity: 1;
                & + .slick-slide { opacity: 1; }
              }
            }
            &-arrow:hover { background-color: #F09933; }
            &-prev:hover { background-position: left 47% center; }
            &-next:hover { background-position: right 47% center; }
          }
        }
      }
    }
  }

  /*
  新卒採用
  -------------------------------------*/
  #PageService.pageNew {
    overflow-y: hidden;
    #Main {
      #ContBox04 {
        .click:hover { opacity: 0.5; }
      }
    }
    #PopBox .popBtn {
      opacity: 1;
      &:hover { opacity: 0.5; }
    }
  }

  /*
  実績紹介　下層共通
  -------------------------------------*/
  #PageAchivements.lower {
    #Main {
      #ContBox05 {
        .contSubBox .subBox {
          .sp_display { display: none !important; }
        }
      }
      #ContBox06 {
        table td a:hover {
          text-decoration: underline;
        }
      }
    }
    #BackBox {
      a {
        &:before {
          transition: background-position 0.5s ease;
        }
        &:hover:before {
          background-color: #F09933;
          background-position: left 45% center;
        }
      }
    }
  }

  /*
  採用コラム
  -------------------------------------*/
  #PageColumn {
    #Main #ContBox01 {
      .cateNav ul li:not(.current) a {
        opacity: 1;
        &:hover { opacity: 0.5; }
      }
      .btnTypeMore {
        &:hover {
          .arrow {
            background-color: #f09933;
            background-position: center bottom 47%;
          }
        }
      }
    }
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageColumn.pageEntry {
    #Main #ContBox01 {
      .pageNav ul li {
        a:hover {
          text-decoration: none;
        }
        &.prev a:hover {
          background-color: #f09933;
          background-position: left 47% center;
        }
        &.next a:hover {
          background-color: #f09933;
          background-position: right 47% center;
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact {
    #Main #ContBox01 {
      .form_btn:hover {
        background-color: #F09933;
        span {
          background-position: right center;
        }
      }
      .form_return {
        a:hover { opacity: 0.5; }
      }
    }
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main #ContBox01 {
      p.home a:hover {
        .arrow {
          background-color: #F09933;
          background-position: left 47% center;
        }
      }
    }
  }

  /*
  404
  -------------------------------------*/
  #Page404.pageIndex {
    #Main .contBox {
      .home a:hover {
        .arrow {
          background-color: #F09933;
          background-position: left 47% center;
        }
      }
    }
  }
}

@media print,
screen and (min-width: 768px) and (max-width: 1440px) {
  #PageService.pageIndex {
    #Main {
      #ContBox02 .contSubBox {
        &:nth-of-type(odd) .content {
          padding-left: 8.3333333333%;
        }
        &:nth-of-type(even) .content {
          padding-right: 8.33333333333333%;
        }
      }
    }
  }
  #PageFeautures.pageIndex {
    #Main {
      #ContBox02 .subBox .box02 .text {
        width: 60%;
        height: 100%;
        right: -35px;
      }
    }
  }
}

@media print,
screen and (min-width: 768px) and (max-width: 1050px) {
  #Page.pageIndex {
    #Main {
      #ContBox02 {
        .subBox .box {
          width: calc( (100% - 40px) / 2 );
          margin-right: 40px;
          &:nth-of-type(3n) { margin-right: 40px; }
          &:nth-of-type(even) { margin-right: 0; }
        }
      }
    }
  }

  #PageAchivements.lower {
    #Main {
      #ContBox04 {
        .contSubBox02 {
          .subBox {
            flex-direction: column;
            .box01 {
              width: 100%;
              flex-direction: column;
              padding: 17px 15px;
              dt {
                width: 100%;
                margin-bottom: 9px;
                margin-right: 0;
                text-align: center;
                padding: 5px 5px 6px;
              }
              dd {
                width: 100%;
                text-align: justify;
              }
            }
            .box02 {
              margin-top: -3px;
              margin-right: 0;
              width: 122px;
              height: 61px;
              background: url(../img/contents/achivements/icon_03_02.png) no-repeat left top;
              background-size: 100% auto;
              padding: 10px 0 0;
              p {
                font-size: 16px;
                text-align: center;
              }
              &:after { display: none; }
            }
            .box03 {
              width: 100%;
              padding: 22px 26px 27px !important;
              p {
                font-size: 15px;
                line-height: 25px;
                text-align: left;
              }
            }
            .box04 {
              left: 50%;
              right: auto;
              bottom: -43px !important;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}

@media print,
screen and (min-width: 1000px) {
  #PageAbout.pageIndex #Main {
    #ContBox01 {
      position: relative;
      &:before {
        content: '';
        width: calc( (100% - 1000px) / 2 );
        height: 98px;
        background-color: #FFFFFF;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    #ContBox02 {
      overflow: hidden;
      .contSubBox02 .subBox {
        .photo {
          width: 720px;
          margin-left: -220px;
        }
      }
    }
  }
}

@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg {
      .mainImgInner {
        padding: 0;
      }
      .svgBox {
        height: auto;
        image {
          height: auto;
        }
      }
      .slick {
        &_item {
          background-position: center bottom;
          position: relative;
          &:before {
            content: '';
            width: 100%;
            height: 350px;
            background: linear-gradient(to bottom, #000 0%, rgba(183, 183, 183, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &_item01 { background-image: url(../img/contents/top/mainimg_01_sp.jpg); }
        &_item02 { background-image: url(../img/contents/top/mainimg_02_sp.jpg); }
        &_item03 { background-image: url(../img/contents/top/mainimg_03_sp.jpg); }
        &_item04 { background-image: url(../img/contents/top/mainimg_04_sp.jpg); }
        &_item05 { background-image: url(../img/contents/top/mainimg_05_sp.jpg); }
      }
      .text {
        width: 100%;
        max-width: 390px;
        padding-left: 40px;
        top: 112px;
        left: 50%;
        transform: translate(-50%, 0);
        h2 {
          font-size: 24px;
          white-space: nowrap;
          &:nth-of-type(2) {
            font-size: 29px;
            margin-top: 6px;
            .svgBox {
              width: 78.71794871794872%;
              margin-right: 6px;
              transform: translateY(3px);
              #MainMask {
                width: 100%;
                height: 100%;
                .image {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    #Main {
      #ContBox01 {
        padding: 121px 0 100px;
        .bgText {
          font-size: 50px;
          top: 59px;
          left: 48.5%;
        }
        .fadeAnimeBox {
          margin-bottom: 18px;
          h3 {
            font-size: 35px;
            span {
              font-size: 40px;
            }
          }
        }
        h4 {
          line-height: 30px;
          margin-bottom: 16px;
        }
        .btnBox {
          margin-top: 35px;
        }
      }
      #ContBox02 {
        padding: 50px 0;
        .fadeAnimeBox {
          margin-bottom: 30px;
          & + p { margin-bottom: 23px; }
        }
        .subBox {
          .box {
            width: 100%;
            margin: 0;
            & + .box {
              margin-top: 20px;
            }
            dt {
              min-height: auto;
              font-size: 18px;
              line-height: 25px;
              padding: 12px 25px 13px;
            }
            dd {
              height: auto;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .box01 dd {
            padding: 16px 20px 31px;
            img { max-width: 257px; }
          }
          .box02 dd {
            padding: 10px 20px 27px;
            img { max-width: 206px; }
          }
          .box03 dd {
            padding: 48px 20px 64px;
            img { max-width: 300px; }
          }
          .box04 dd {
            padding: 42px 20px 59px;
            img { max-width: 288px; }
          }
          .box05 dd {
            padding: 21px 20px 16px;
            img { max-width: 290px; }
          }
          .box06 dd {
            padding: 18px 20px 19px;
            img { max-width: 296px; }
          }
        }
      }
      #ContBox03 {
        padding: 53px 0 70px;
        .fadeAnimeBox {
          margin-bottom: 25px;
        }
      }
      #ContBox04 {
        padding: 50px 0;
        .fadeAnimeBox {
          margin-bottom: 39px;
        }
        .subBox {
          flex-wrap: wrap;
          .box {
            width: 100%;
            .image {
              height: 219px;
            }
            .content {
              padding: 19px 30px 42px;
              h4 {
                font-size: 18px;
              }
              p {
                line-height: 25px;
                margin-bottom: 10px;
              }
            }
          }
          .box01 {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
      #ContBox05 {
        padding: 54px 0 76px;
        .fadeAnimeBox {
        }
        .postTypeThumb {
          width: calc(100% + 20px);
          .post {
            width: calc(100vw - 50px);
            max-width: 340px;
            margin-right: 10px;
            a {
              .thumbnail {
                height: 200px;
              }
            }
          }
          .slick-prev { left: -12px; }
          .slick-next { right: 6px; }
        }
        .innerBasic > p {
          margin-top: 15px;
        }
      }
    }
  }

  /*
  エンライブンの特徴
  -------------------------------------*/
  #PageFeautures.pageIndex {
    #Main {
      #ContBox01 {
        padding: 30px 0 68px;
        .title {
          font-size: 23px;
          margin-bottom: 17px;
        }
        h4 {
          font-size: 28px;
          line-height: 40px;
          text-align: justify;
          margin-bottom: 41px;
        }
        .subBox {
          flex-direction: column-reverse;
          margin-bottom: 20px;
          .image {
            width: 100%;
            height: 230px;
          }
          .content {
            width: 100%;
            padding: 60px 19px 28px;
            .num {
              font-size: 100px;
              top: -13px;
              left: -6px;
            }
          }
          &:nth-of-type(even) {
            flex-direction: column-reverse;
            .content {
              padding: 60px 19px 28px;
              .num {
                top: -13px;
                left: -6px;
                right: auto;
              }
            }
          }
        }
        .subBox01 .image { background-image: url(../img/contents/feautures/img_01_02.jpg); }
        .subBox02 .image { background-image: url(../img/contents/feautures/img_02_02.jpg); }
        .subBox03 .image { background-image: url(../img/contents/feautures/img_03_02.jpg); }
      }
      #ContBox02 {
        margin-top: 0;
        padding-bottom: 52px;
        .boxImage {
          height: 0;
          padding-top: 51.28205128205128%;
          background-image: url(../img/contents/feautures/img_04_02.jpg);
          margin-bottom: 17px;
        }
        .boxTitle {
          h3 {
            font-size: 23px;
            line-height: 40px;
            position: relative;
            padding-top: 26px;
            &:before {
              font-size: 25px;
              line-height: 32px;
              width: 100vw;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          & + p {
            font-size: 15px;
            text-align: justify;
            margin: 17px 0 18px;
          }
        }
        .subBox {
          flex-direction: column;
          .box {
            width: 100%;
          }
          .box01 {
            margin: 0 0 63px;
            padding: 20px 13px;
            h4 {
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 17px;
            }
            .list {
              padding: 17px 15px 18px;
            }
            &:after {
              width: 62px;
              height: 28px;
              background-image: url(../img/contents/feautures/icon_01_02.png);
              top: auto;
              right: auto;
              left: 50%;
              bottom: -46px;
              transform: translate(-50%, 0);
            }
          }
          .box02 {
            padding: 20px 23px 22px 16px;
            .boxInner {
              width: 100%;
              max-width: 311px;
              margin: 0 auto;
            }
            img {
              width: 100%;
              height: auto;
            }
            .text {
              width: 66.88102893890675%;
              height: 61.09422492401216%;
              top: 0;
              right: -32px;
            }
          }
        }
      }
      #ContBox03 {
        padding-bottom: 53px;
        .boxImage {
          height: 0;
          padding-top: 51.28205128205128%;
          background-image: url(../img/contents/feautures/img_06_02.jpg);
          margin-bottom: 15px;
          span {
            font-size: 18px !important;
            white-space: wrap;
            width: calc(100% - 40px);
            max-width: 350px;
            text-align: center;
          }
        }
        h3 {
          font-size: 23px;
          margin-bottom: 20px;
          & + p { margin-bottom: 25px; }
        }
        table {
          th {
            width: 19%;
            height: 55px;
            font-size: 10px;
            line-height: 12px;
            border-radius: 16px 13px 0 0;
          }
          td {
            width: 19%;
            p {
              font-size: 11px;
              line-height: 15px;
            }
            span[class^="icon"] {
              width: 25px;
              height: 25px;
            }
            &:first-of-type { width: 43%; }
          }
        }
      }
    }
  }

  /*
  事業紹介
  -------------------------------------*/
  #PageService.pageIndex {
    background-color: #F7F7F7;
    #Main {
      #ContBox01 {
        padding-bottom: 40px;
        .boxImage {
          height: 200px;
          background-image: url(../img/contents/service/img_01_02.jpg);
          span { left: 20px; }
        }
        h3 {
          font-size: 23px;
          margin-bottom: 15px;
        }
        .scrollBox {
          padding-bottom: 38px;
          &::-webkit-scrollbar-thumb {
            background-color: #FFFFFF;
          }
        }
        .subBox {
          width: 1238px;
        }
      }
      #ContBox02 {
        padding-bottom: 0;
        .contSubBox {
          flex-direction: column-reverse;
          .image {
            width: 100%;
            height: 200px;
            border-radius: 0;
          }
          .content {
            width: 100%;
            padding: 0 20px;
          }
          &:nth-of-type(even) {
            flex-direction: column-reverse;
            .image {
              width: 100%;
              border-radius: 0;
            }
            .content {
              padding-right: 20px;
              padding-left: 20px;
            }
          }
        }
        .contSubBox01 {
          min-height: auto;
          margin-bottom: 0;
          .image {
            background-image: url(../img/contents/service/img_02_02.jpg);
          }
          .content {
            padding-top: 40px;
            padding-bottom: 28px;
            .fadeAnimeBox {
              margin-bottom: 35px;
              h3 { margin-top: 5px; }
            }
            p {
              margin-bottom: 32px;
              &:last-of-type { margin-bottom: 0; }
            }
          }
        }
        .contSubBox02 {
          .image {
            background-image: url(../img/contents/service/img_03_02.jpg);
            background-position: center top;
          }
          .content {
            padding-top: 20px;
            padding-bottom: 49px;
          }
        }
      }
    }
  }

  /*
  新卒採用
  -------------------------------------*/
  #PageService.pageNew {
    background-color: #F7F7F7;
    #Main {
      #ContBox01 {
        height: auto;
        margin-bottom: 12px;
        .boxImage {
          height: 200px;
          background-position: center top;
          position: relative;
        }
        .innerBasic {
          padding: 0;
        }
        .text {
          padding: 32px 20px 43px;
          h3 {
            font-size: 23px;
            line-height: 38px;
            margin-bottom: 14px;
          }
        }
      }
      #ContBox02 {
        padding: 34px 0 28px;
        h3 {
          font-size: 23px;
          line-height: 35px;
          margin-bottom: 30px;
        }
        .subBox {
          flex-direction: column-reverse;
          .image {
            width: 100%;
            height: 200px;
            background-image: url(../img/contents/service/recruitment/img_02_02.jpg);
          }
          .content {
            padding: 21px 20px 32px;
            ul li {
              font-size: 15px;
              line-height: 20px;
              padding-left: 34px;
              &:before {
                width: 29px;
                height: 24px;
                top: -5px;
              }
              & + li {
                margin-top: 15px;
              }
            }
          }
        }
      }
      #ContBox03 {
        padding: 86px 0 61px;
        .boxTitle {
          margin-bottom: 21px;
          h3 {
            font-size: 23px;
            &:before {
              font-size: 100px;
              margin-bottom: 5px;
            }
          }
        }
        .leadTxt {
          text-align: justify;
          margin-bottom: 28px;
        }
        .flowBox {
          .box {
            flex-direction: column;
            & + .box {
              margin-top: 12px;
            }
            .image {
              width: 100%;
              height: 180px;
            }
            .content {
              padding: 20px 32px 25px;
              .title {
                flex-direction: column;
                margin-bottom: 10px;
                .label {
                  font-size: 20px;
                  padding: 0 32px;
                  margin-right: 0;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
      #ContBox04 {
        padding: 28px 0 60px;
        h3 {
          font-size: 23px;
          margin-bottom: 14px;
        }
        .qaBox {
          .qa {
            & + .qa {
              margin-top: 15px;
            }
            .click {
              p {
                min-height: 60px;
                line-height: 20px;
                padding: 10px 30px 10px 54px;
                display: flex;
                align-items: center;
                &:before {
                  top: 50%;
                  left: 14px;
                  transform: translateY(-50%);
                }
              }
            }
            .showBox {
              padding: 12px 15px 22px;
              &:before {
                display: block;
                line-height: 1;
                margin-bottom: 4px;
                position: relative;
                top: 0;
                left: 0;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
      #PopBox {
        background-color: #151515;
        .innerBasic {
          padding: 48px 50px;
        }
        .box {
          width: 100%;
          .boxContent {
            padding: 20px 17px;
          }
          .content {
            margin-top: 20px;
            .title {
              flex-direction: column;
              font-size: 20px;
              line-height: 25px;
              text-align: center;
              margin-bottom: 20px;
              .label {
                font-size: 20px;
                padding: 0 32px;
                margin-right: 0;
                margin-bottom: 8px;
              }
            }
            p {
              line-height: 25px;
            }
          }
        }
        .popBtnPrev,
        .popBtnNext {
          span {
            &.arrow {
              width: 44px;
              height: 44px;
            }
            &.text {
              font-size: 12px;
              margin-top: 2px;
            }
          }
        }
        .popBtnPrev {
          left: 3px;
        }
        .popBtnNext {
          right: 3px;
        }
      }
    }
  }

  /*
  実績紹介
  -------------------------------------*/
  #PageAchivements.pageIndex {
    #Main {
      #ContBox01 {
        padding: 21px 20px 102px;
      }
    }
  }

  /*
  実績紹介　下層共通
  -------------------------------------*/
  #PageAchivements.lower {
    background-color: #F7F7F7;
    #MainImg {
      background-color: #F7F7F7;
      padding-bottom: 21px;
    }
    #Main {
      #ContBox01 {
        height: auto;
        background-color: #FFFFFF;
        .boxImage {
          height: 300px;
          background-position: center top;
          position: relative;
          z-index: 0;
        }
        .innerBasic {
          padding: 0;
        }
        .text {
          width: 100%;
          min-height: 100px;
          background-color: #151515;
          padding: 30px 20px;
          display: flex;
          align-items: center;
          position: relative;
          &:before {
            width: 46px;
            height: 46px;
            background: linear-gradient(#ff550d 0%, #fd790b 53.71%, #ffb100 100%);
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            top: -29px;
            left: 50%;
            transform: translateX(-50%);
          }
          h3 {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 20px;
            &:before {
              top: -57px !important;
              left: 50% !important;
              transform: translate(-50%, 0) !important;
            }
            + h3 {margin-top: 0; }
          }
        }
      }
      #ContBox02 {
        background-color: #FFFFFF;
        padding: 32px 0 54px;
        table {
          th, td {
            width: 100%;
          }
          td {
            line-height: 25px;
            background-color: #F7F7F7;
          }
        }
      }
      #ContBox03 {
        padding: 35px 0;
        h3 {
          text-align: center;
          margin-bottom: 15px;
        }
        .subBox {
          .box {
            width: calc( ( 100% - 10px ) / 2 ) !important;
            margin-right: 10px;
            margin-bottom: 16px;
            border-radius: 30px 0 30px 0;
            & + .box {
              margin-left: 0;
            }
            &:nth-of-type(even) {
              margin-right: 0;
            }
            dt {
              font-size: 14px;
              line-height: 15px;
              min-height: 45px;
            }
            dd {
              padding: 10px 14px 18px;
              p {
                &.num {
                  font-size: 25px;
                  span {
                    font-size: 40px;
                  }
                  &:after {
                    width: 50px;
                    height: 35px;
                  }
                }
                &.numPeo {
                  margin-top: 0;
                  span {
                    &:nth-of-type(2):before {
                      width: 7px;
                      height: 13px;
                      left: -12px;
                    }
                  }
                }
              }
            }
          }
          > p {
            font-size: 10px;
            margin-top: -10px;
          }
        }
      }
      #ContBox04 {
        background-color: #FFFFFF;
        padding: 29px 0 103px;
        .contSubBox01 {
          margin-bottom: 29px;
          h3 {
            margin-bottom: 10px;
          }
          .subBox {
            padding: 27px;
            flex-direction: column;
            ul {
              width: 100%;
              & + ul {
                padding-left: 0;
                margin-top: 15px;
              }
              li {
                font-size: 16px;
                &:before { top: 6px; }
              }
            }
          }
        }
        .contSubBox02 {
          h3 {
            margin-bottom: 17px;
          }
          .subBox {
            flex-direction: column;
            .box01 {
              width: 100%;
              flex-direction: column;
              padding: 17px 15px;
              dt {
                width: 100%;
                margin-bottom: 9px;
                margin-right: 0;
                text-align: center;
                padding: 5px 5px 6px;
              }
              dd {
                width: 100%;
                text-align: justify;
              }
            }
            .box02 {
              margin-top: -3px;
              margin-right: 0;
              width: 122px;
              height: 61px;
              background: url(../img/contents/achivements/icon_03_02.png) no-repeat left top;
              background-size: 100% auto;
              padding: 10px 0 0;
              p {
                font-size: 16px;
                text-align: center;
              }
              &:after { display: none; }
            }
            .box03 {
              width: 100%;
              padding: 22px 26px 27px;
              p {
                font-size: 15px;
                line-height: 25px;
                text-align: left;
              }
            }
            .box04 {
              left: 50%;
              right: auto;
              bottom: -43px !important;
              transform: translateX(-50%);
            }
          }
        }
      }
      #ContBox05 {
        padding: 25px 0 37px;
        .title {
          margin-bottom: 30px;
          h3 {
            font-size: 18px;
            &:before {
              font-size: 40px;
              margin-bottom: 0;
            }
          }
        }
        .contSubBox {
          h4 {
            font-size: 18px;
            line-height: 20px;
            padding: 12px 21px 13px 32px;
            margin-bottom: 16px;
          }
          .subBox {
            .photoRight,
            .photoLeft {
              float: none;
              margin: 0 0 16px;
            }
            img {
              width: 100%;
              &:last-of-type { margin-bottom: 16px; }
              &.pc_display { display: none; }
            }
            p + img {
              margin-top: -9px;
            }
          }
        }
      }
      #ContBox06 {
        background-color: #FFFFFF;
        padding: 32px 0;
        h3 {
          font-size: 18px;
          text-align: center;
          margin-bottom: 14px;
        }
        table {
          th {
            width: 100%;
          }
        }
      }
    }
    #BackBox {
      height: 127px;
      a {
        font-size: 15px;
        padding-left: 63px;
        &:before {
          width: 47px;
          height: 47px;
          background-size: 13px auto;
        }
      }
    }
  }

  /*
  株式会社トップ精工
  -------------------------------------*/
  #PageAchivements.page01.lower {
    #Main {
      #ContBox01 {
        .boxImage {
          background-image: url(../img/contents/achivements/lower01_img_01_sp.jpg);
        }
        .text:before {
          content: '01';
        }
      }
      #ContBox02 {
        .logo {
          width: 216px;
          height: 33px;
          margin-bottom: 40px;
        }
      }
    }
  }

  /*
  株式会社日本プロパティシステムズ
  -------------------------------------*/
  #PageAchivements.page02.lower {
    #Main {
      #ContBox01 {
        .text:before {
          content: '02';
        }
      }
      #ContBox02 {
        padding-top: 25px;
        .logo {
          width: 272px;
          height: 52px;
          margin-bottom: 28px;
        }
      }
      #ContBox04 {
        .contSubBox02 .subBox {
          .box02 { padding: 8px 0 0; }
          .box03 {
            padding: 22px 26px 39px;
          }
        }
      }
    }
  }

  /*
  株式会社八興
  -------------------------------------*/
  #PageAchivements.page03.lower {
    #Main {
      #ContBox01 {
        .innerBasic {
          padding-left: 0;
        }
        .text:before {
          content: '03';
        }
      }
      #ContBox02 {
        .logo {
          width: 243px;
          height: 52px;
          margin-bottom: 33px;
        }
      }
      #ContBox03 {
        .subBox .box01 dd p.numPeo span {
          font-size: 22px;
        }
      }
      #ContBox04 {
        .contSubBox02 .subBox {
          .box04 {
            width: 166px;
            background-image: url(../img/contents/achivements/icon_04_01.png);
          }
        }
      }
      #ContBox05 {
        .floatSpace {
          display: none;
        }
      }
    }
  }

  /*
  採用コラム
  -------------------------------------*/
  #PageColumn {
    #Main {
      #ContBox01 {
        padding: 21px 0 47px;
        .cateNav {
          margin-bottom: 40px;
          ul {
            justify-content: space-between;
            li {
              width: calc( (100% - 24px) / 2 );
              margin-top: 20px;
              margin-right: 0;
              text-align: center;
              &:first-of-type, &:nth-of-type(2) {
                margin-top: 0;
              }
            }
          }
        }
        .postTypeThumb {
          .post {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            &:last-of-type {
              margin-bottom: 0;
            }
            a {
              .thumbnail {
                height: 200px;
              }
            }
          }
        }
        .btnTypeMore {
          margin-top: 48px;
          .text {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: -1px;
          }
          .arrow {
            width: 47px;
            height: 47px;
            background-size: 12px auto;
            margin: 0 auto;
          }
        }
      }
    }
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PageColumn.pageCategory {
    #Main {
      #ContBox01 {
        h3 {
          margin-bottom: 40px;
        }
      }
    }
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageColumn.pageEntry {
    #Main {
      #ContBox01 {
        padding-bottom: 48px;
        .cateNav {
          margin-bottom: 40px;
        }
        .subBox {
          .entryTitle {
            font-size: 23px;
            line-height: 30px;
            padding: 26px 25px;
          }
          .thumbnail {
            padding-top: 65.71428571428571%;
          }
          .entryBox {
            padding: 40px 20px 48px;
            .date {
              margin-bottom: 15px;
            }
          }
        }
        .pageNav {
          margin-top: 48px;
          ul {
            width: calc( 100% - 56px);
            li {
              &:not(.home) a {
                width: 47px;
                height: 47px;
                background-size: 12px auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  エンライブンについて
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main {
      .contBox {
        .nameBox {
          .position{
            font-size: 14px;
            margin-bottom: 2px;
          }
          p{
            font-size: 18px;
            .en{
              font-size: 18px;
              white-space: nowrap;
            }
          }
        }
      }
      #ContBox01 {
        height: 340px;
        background-image: url(../img/contents/about/img_01_sp.jpg);
        .nameBox {
          padding: 20px 42px 16px 20px;

        }
      }
      #ContBox02 {
        padding: 20px 0 65px;
        .contSubBox {
          .title {
            text-align: center;
            margin-bottom: 31px;
            h3{
              text-align: center;
              &:before {
                font-size: 60px;
                text-align: center;
                margin-bottom: -20px;
              }
            }
          }
          p{
            margin-bottom: 30px;
            line-height: 2.0;
          }
        }
        .contSubBox01 {
          margin-bottom: 40px;
          h4{
            line-height: 1.5;
            margin-bottom: 37px;
          }
        }
        .contSubBox02 {
          .title{
            margin-bottom: 26px;
            .en{
              font-size: 40px;
              text-align: left;
            }
          }
          h4{
            font-size: 23px;
            line-height: 1.5;
            letter-spacing: 0.05em;
            margin-bottom: 41px;
          }
          .subBox{
            display: block;
            &> div{
              width: 100%;
            }
            .photo{
              height: 260px;
              width: calc(100% + 40px);
              margin-left: -20px;
              background-image: url(../img/contents/about/img_02_sp.jpg);
              .nameBox{
                left: 0;
                max-width: 232px;
                padding: 20px 20px 7px;
                .position{
                  margin-bottom: 0;
                }
              }
            }
            .content{
              padding: 35px 0 0;
            }
          }
        }
      }
      #ContBox03{
        padding: 40px 15px 68px;
        .title{
          margin-bottom: 27px;
          h3{
            font-size: 23px;
          }
        }
        .subBox {
          table {
            margin-bottom: 0;
            th{
              width: 100%;
            }
            td{
              padding: 5px 18px 11px;
              line-height: 1.65;
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact{
    #Main {
      #ContBox01 {
        .form_btn{
          margin-top: 31px;
        }
      }
    }
  }
  #PageContact.pageIndex {
    #Main {
      #ContBox01 {
          padding: 37px 0 40px;
          p{
            text-align: left;
            margin-bottom: 14px;
          }
          .form{
            input{
              padding: 18px 18px;
              font-size: 15px;
            }
            textarea{
              padding: 10px 18px;
              height: 250px;
            }
          }
          .form_table {
            th{
              font-size: 15px;
            }
            td{
              margin-bottom: 16px;
            }
          }
      }
    }
  }

  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main {
      #ContBox01 {
        padding: 40px 0 35px;
        h3{
          font-size: 18px;
          margin-bottom: 25px;
        }
        &> p{
          &:first-of-type, &:last-of-type{
            padding: 0 20px;
          }

        }
        .form{
          margin-bottom: 20px;
        }
        .tableBox{
          margin: 28px 0;
          padding: 31px 20px 28px;
        }
        .form_table{
          th{
            font-size: 15px;
            padding-top: 1px;
            padding-bottom: 2px;
          }
          td{
            margin-bottom: 24px;
          }
        }
        .form_btn span{
          background-position: right center;
        }
        .form_return{
          margin-top: 16px;
          position: relative;
          left: -6px;
        }
      }
    }
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main {
      min-height: 370px;
      #ContBox01 {
        padding: 40px 0 42px;
        h3{
          font-size: 18px;
          margin-bottom: 26px;
          letter-spacing: 0.03em;
        }
        p{
          &:not(.home){
            width: 100%;
            margin-bottom: 42px;
          }
          &.home{
            position: relative;
            left: -6px;
            a {
              letter-spacing: 0.06em;
              .arrow{
                width: 47px;
                height: 47px;
                background-size: 13px auto;
                margin-right: 18px;
              }
            }
          }
        }

      }
    }
  }

  /*
  個人情報保護方針
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #Main {
      #ContBox01{
        padding: 50px 0 43px;
        h3{
          font-size: 23px;
        }
        .innerBasic>p:last-of-type{
          margin-top: -21px;
        }
        .subBox{
          margin-top: 28px;
          h4{
            line-height: 1.8;
          }
        }
        .subBox02{
          ul{
            margin-top: 4px;
            margin-bottom: 35px;
          }
          .listTypeCircle li{
            padding-left: 8px;
            &:before{
              width: 4px;
              height: 4px;
              top: 10px;
            }
          }
        }
        .subBox06{
          .box{
            margin-top: 17px;
            padding: 12px 22px;
          }
        }
      }
    }
  }

  /*
  404
  -------------------------------------*/
  #Page404.pageIndex {
    #Main {
      min-height: auto;
      .contBox {
        .home{
          a {
            letter-spacing: 0.06em;
            .arrow{
              width: 47px;
              height: 47px;
              background-size: 13px auto;
              margin-right: 18px;
            }
          }
        }
      }
    }
  }
}

@media print,
screen and (max-width: 345px) {
  #Page.pageIndex {
    #MainImg .text {
      padding-left: 0;
      left: 0;
      transform: translate(0,0);
      h2 { white-space: normal; }
    }
  }
  #PageFeautures.pageIndex {
    #Main #ContBox02 {
      .subBox .box02 .text p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}