@charset "utf-8";

/*---------------------------------
  bit Common CSS 2018.2
  CSS inoue Document 2018.02
  Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+JP:wght@100..900&display=swap');


$font_basic01: "Caveat", cursive, sans-serif;
$font_basic02: "Montserrat", sans-serif;


/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.5;
  text-align: center;
  font-family: "Noto Sans JP", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  color: #151515;
}

p {
  font-size: 16px;
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: #F09933;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &[x-apple-data-detectors] {
    line-height: inherit !important;
    text-decoration: none !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    pointer-events: none;
  }
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Main {
  min-height: 500px;
  _height: 500px;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}


/*####################　ヘッダ　####################*/
#Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 52;
  .headerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h1 {
    a {
      width: 119px;
      height: 25px;
      display: block;
      background-repeat: no-repeat;
      background-position: left top;
      background-image: url(../img/header/logo_bk.png);
      background-size: 100% auto;
      text-decoration: none;
      text-indent: -9999px;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 231px;
    background: linear-gradient(#5a5a5a 0%, rgba(183, 183, 183, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
  }
}

.fixed #Header {
  &:before {
    background: linear-gradient(#f7f7f7 0%, rgba(247, 247, 247, 0.8) 29.88%, rgba(247, 247, 247, 0.79) 40.27%, rgba(247, 247, 247, 0) 57.46%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
  }
}

/*####################　コンテンツ部　####################*/
/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
-------------------------------------*/
#TopicPath {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 18px;
  ol {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    list-style: none;
    line-height: 1.2;
    color: #646464;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      position: relative;
      margin-left: 10px;
      text-transform: uppercase;
      &:before {
        content: '';
        width: 13px;
        height: 21px;
        background: url(../img/contents/topicpath.png) no-repeat left top;
        background-size: 100% auto;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &.home {
        padding-left: 0;
        margin-left: 0;
        &:before { display: none; }
      }
      a {
        color: #646464;
      }
    }
  }
}

/*　メインイメージ
-------------------------------------*/
#MainImg {
  width: 100%;
  height: 243px;
  .mainImgInner {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    text-align: left;
    padding-top: 41px;
    margin: 0 auto;
    position: relative;
    .text {
      h2 {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
}

/*　個別要素
-------------------------------------*/
.btnTypeCircleArrow {
  display: block;
  width: 75px;
  height: 75px;
  background-color: #151515;
  border-radius: 50%;
  position: relative;
  span {
    width: 20px;
    height: 19px;
    background: url(../img/contents/arrow_02_01.png) no-repeat center center;
    background-size: 100% auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: #F09933;
    span { left: 52.5% }
  }
}
.btnTypeBg {
  display: flex;
  align-items: center;
  width: 400px;
  height: 160px;
  overflow: hidden;
  color: #FFFFFF;
  text-decoration: none;
  border-radius: 10px;
  position: relative;
  .image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #969696;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .text {
    position: relative;
    z-index: 1;
    width: 100%;
    background: url(../img/contents/arrow_04_01.png) no-repeat right 32px bottom;
    background-size: 46px auto;
    padding: 0 80px 0 43px;
    p {
      font-size: 25px;
      font-weight: 600;
      color: #FFFFFF;
      text-shadow: 0 3px 6px rgba(0,0,0,0.65);
      margin-bottom: 0;
      position: relative;
      &:before {
        content: attr(data-entitle);
        display: block;
        font-family: $font_basic01;
        font-size: 30px;
        font-weight: bold;
        color: #A5A5A5;
        line-height: 1;
        text-shadow: 0 0 0 rgba(0,0,0,0);
      }
    }
  }
}
.btnTypeText {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #151515;
  text-decoration: none;
  text-transform: uppercase;
  background: url(../img/contents/arrow_03_01.png) no-repeat right 1px center;
  background-size: 16px auto;
  padding-right: 24px;
  margin-left: auto;
}

.colorRed {
  color: #F05433 !important;
}

a[target="_blank"] {
  padding-right: 17px;
  background: url(../img/contents/target_01.png) no-repeat right top 7px;
  background-size: 11px auto;
}

/*　アニメーション
-------------------------------------*/
.fadeAnimeBox {
  margin-bottom: 30px;
  /*一部の子要素*/
  &.anime .fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  /*すべの子要素*/
  &.anime.fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  h3 {
    margin-top: -6px;
  }
}
@keyframes fadeInAnime{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*　svg
-------------------------------------*/
#MainMask,
#MaskDate,
#MaskAchievement,
#MaskService,
#MaskColumn,
#Mask,
#ContactMask {
  .st0 {
    fill:none;
    stroke:#fff;
    stroke-width:90;/*線の太さを指定する*/
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
    stroke-dasharray: 1500; /* 線の間隔を指定する */
    stroke-dashoffset:1500; /* 線の位置を指定する */
  }
}


/*　メイン
-------------------------------------*/
#Main {
  .titleTypeGrad {
    span {
      display: inline-block;
      background: linear-gradient(-10.202deg, #FFB100 0%, #FF550D 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .titleTypeBold {
    font-size: 30px;
    font-weight: 600;
    text-align: justify;
  }
  .titleTypeBgBorder {
    font-size: 22px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 32px;
    text-align: justify;
    background-color: #151515;
    padding: 7px 21px 8px 32px;
    margin-bottom: 24px;
    position: relative;
    &:before {
      content: '';
      width: 11px;
      height: 100%;
      background: linear-gradient(#ff550d 0%, #fd790b 53.71%, #ffb100 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .hasSubTitle {
    &:before {
      content: attr(data-subtitle);
      font-family: $font_basic01;
      font-weight: bold;
      color: #8E8E8E;
      line-height: 1;
      text-align: center;
      display: block;
    }
  }
  .hasSubTitleBg {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:before {
      content: attr(data-subtitle);
      font-family: $font_basic01;
      font-size: 20px !important;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      background-color: #838383;
      padding: 1px 9px 1px 7px;
    }
  }

  .textTypeBg {
    display: inline-block;
    font-size: 20px !important;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    background-color: #838383;
    padding: 1px 9px 1px 7px;
    &.en {
      font-family: $font_basic01;
      font-weight: bold;
    }
  }
  .textTypeBgBlack {
    font-weight: 600;
    color: #FFFFFF;
    background-color: #151515;
    padding: 13px 14px;
  }
  .textTypeLine {
    color: #F09933;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      border-top: 1px solid #F09933;
      position: absolute;
      left: 0;
      bottom: -2px;
    }
  }
  .textTypeNote {
    font-size: 12px;
    text-align: justify;
    line-height: 1.5;
    padding-left: 1.1em;
    position: relative;
    &:before {
      content: '※';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .textTypeRight {
    text-align: right !important;
  }

  .listTypeCircle {
    li {
      text-align: justify;
      line-height: 20px;
      padding-left: 11px;
      position: relative;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #151515;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 0;
      }
      & + li { margin-top: 10px; }
    }
  }

  .tableTypeBasic {
    width: 100%;
    margin-bottom: 37px;
    tr:last-of-type {
      th, td { border-bottom-width: 0; }
    }
    th, td {
      border-bottom: 6px solid #FFFFFF;
    }
    th {
      width: 29%;
      font-weight: 600;
      color: #FFFFFF;
      vertical-align: middle;
      background-color: #767676;
      padding: 8px 16px;
      border-right: 5px solid #FFFFFF;
    }
    td {
      background-color: #F7F7F7;
      line-height: 20px;
      text-align: justify;
      padding: 13px;
    }
  }

  .postTypeThumb {
    .post {
      a {
        color: #151515;
        text-decoration: none;
        background-color: #FFFFFF;
        border-radius: 50px 0 50px 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .thumbnail {
          height: 250px;
          overflow: hidden;
          figure {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 42px 31px 46px;
          .date {
            font-family: $font_basic02;
            font-weight: 500;
            color: #A8A8A8;
            margin-bottom: 10px;
          }
          .title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 34px;
          }
          .btnTypeText {
            margin-top: auto;
            margin-left: auto;
          }
        }
      }
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .btnBox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
    > a {
      margin-right: 16px;
      margin-bottom: 16px;
      &:last-of-type { margin-right: 0; }
    }
  }
  .achivBox {
    .box {
      min-height: 470px;
      background-color: #FFFFFF;
      text-decoration: none;
      color: #151515;
      border-radius: 50px 0 50px 0;
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      &:last-of-type {
        margin-bottom: 0;
      }
      .image {
        width: 50%;
        display: flex;
        flex-direction: column;
        dt {
          flex: 1;
          figure {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
        dd {
          background-color: #151515;
          display: flex;
          align-items: center;
          padding: 21px 25px;
          .num {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #A3A3A3;
            margin-right: 20px;
          }
          p {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: #FFFFFF;
            flex: 1;
            margin-bottom: 0;
          }
        }
      }
      .content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 45px;
        h4 {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
        table {
          margin-bottom: 37px;
        }
      }
    }
    .box01 {
      .image dt figure { background-image: url(../img/contents/top/img_03.jpg); }
      .content {
        h4 {
          max-width: 356px;
          margin-bottom: 42px;
        }
      }
    }
    .box02 {
      .image dt figure { background-image: url(../img/contents/top/img_04.jpg); }
      .content {
        h4 {
          max-width: 408px;
          margin-bottom: 23px;
        }
      }
    }
    .box03 {
      .image dt figure { background-image: url(../img/contents/top/img_07.jpg); }
      .content {
        h4 {
          max-width: 343px;
          margin-bottom: 28px;
        }
      }
    }
  }
}

/*　お問い合わせ
-------------------------------------*/
#Contact {
  width: 100%;
  height: 400px;
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #151515;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    .background {
      width: 100%;
      height: 100%;
      background: url(../img/contents/contact_bg_01.jpg) no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s ease;
      transform: scale(1);
    }
    .innerBasic {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      h3 {
        font-size: 30px;
        font-weight: 600;
        margin-right: 14px;
      }
    }
    p {
      line-height: 25px;
      margin-bottom: 0;
    }
    &:hover {
      .background {
        transform: scale(1.12);
      }
      .btn {
        background-color: #F09933;
        span { left: 52.5% }
      }
    }
  }
}


/*####################　フッタ　####################*/
#Footer {
  background-color: #3E3E3E;
  .footerInner {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    a {
      display: block;
      width: 171px;
      height: 36px;
      text-indent: -9999px;
      background: url(../img/footer/logo.png) no-repeat left top;
      background-size: 100% auto;
      margin: 0 auto;
    }
  }
  .footerMenu {
    margin: 51px 0 0;
    border-top: 1px solid #939393;
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        a {
          display: block;
          font-weight: 600;
          color: #FFFFFF;
          text-decoration: none;
          text-transform: uppercase;
          opacity: 1;
          &:hover { opacity: 0.5; }
        }
        & + li { margin-left: 40px; }
      }
      &.subMenu {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li a {
          font-size: 12px;
          color: #D0D0D0;
        }
      }
    }
  }
  #PageTop {
    font-size: 12px;
    color: #D0D0D0;
    background: url(../img/footer/arrow.png) no-repeat left center;
    background-size: 11px auto;
    padding-left: 18px;
    margin-top: 51px;
    margin-left: 0;
  }
  #CopyRight {
    width: 100%;
    height: 70px;
    background-color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    small {
      font-size: 12px;
      color: #9B9B9B;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
}

/* アコーディオン用css */
/*#################### PC ####################*/
@media print, screen and (min-width: 768px) {
  body {
    padding-top: 90px;
    scroll-padding-top: 90px;
  }
  p {
    line-height: 30px;
    margin-bottom: 30px;
  }
  a {
    &[href^="tel"] {
      text-decoration: none;
      pointer-events: none;
      &:hover {
        cursor: text;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
  .innerBasic{
    max-width: 1200px;
    margin: 0 auto;
  }
  /*####################　ヘッダ　####################*/
  #Header {
    .headerInner {
      justify-content: space-between;
      padding: 20px 38px;
    }
  }
  #HeaderMenu {
    ul {
      display: flex;
      align-items: center;
      li {
        a {
          display: block;
          font-size: 25px;
          color: #151515;
          text-decoration: none;
          text-transform: capitalize;
        }
        &:not(.menu07) {
          font-family: $font_basic01;
          margin-left: 30px;
          a:hover { text-decoration: underline; }
        }
        &.menu07 {
          margin-left: 20px;
          a {
            font-size: 18px;
            font-weight: 600;
            padding: 10px 21px 11px 20px;
            border: 1px solid #151515;
            border-radius: 10px;
            position: relative;
            span {
              display: inline-block;
              background: url(../img/contents/arrow_01_01.png) no-repeat right center;
              background-size: 14px auto;
              padding-right: 18px;
              position: relative;
            }
            &:before {
              content: '';
              width: calc(100% + 2px);
              height: calc(100% + 2px);
              background: linear-gradient(to right, #ff8126 0%, #ffba00 100%);
              border-radius: 10px;
              display: none;
              position: absolute;
              top: -1px;
              left: -1px;
            }
            &:hover {
              color: #FFFFFF !important;
              span { background-image: url(../img/contents/arrow_02_01.png) !important; }
              &:before {
              display: block;
              }
            }
          }
        }
      }
    }
  }
  /*####################　グローバルナビ　####################*/
  #GlobalNav {}
  #MenuButton, #Wall, #HeaderMenuSp {
    display: none !important;
  }
  /*####################　サブメニュー　####################*/
  #HeaderSub {
    display: none;
  }
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    ol li {
      padding-left: 23px;
      font-size: 12px;
      a:hover {
        text-decoration: none;
      }
    }
  }

  /*　個別要素
  -------------------------------------*/
  .btnTypeBg {
    .image {
      transform: scale(1);
      transition: 0.5s ease;
    }
    &:hover .image {
      transform: scale(1.12);
    }
  }
  .btnTypeText {
    &:hover {
      color: #F09933;
      background-position: right center;
      cursor: pointer;
    }
  }

  /*　メイン
  -------------------------------------*/
  #Main {
    .postTypeThumb {
      .post a {
        .thumbnail figure {
          transform: sacle(1);
          transition: 0.5s ease;
        }
        &:hover {
          .thumbnail figure {
            transform: scale(1.12);
          }
          .btnTypeText {
            background-position: right center;
            color: #f09933;
          }
        }
      }
    }

    .achivBox {
      .box {
        .image dt {
          overflow: hidden;
          figure {
            transform: scale(1);
            transition: 0.5s ease;
          }
        }
        &:hover {
          .image dt figure {
            transform: scale(1.12);
          }
          .content > span {
            background-position: right center;
            color: #f09933;
          }
        }
      }
    }
  }

  /*####################　フッタ　####################*/
  #Footer {
    .footerInner {
      padding: 78px 0 20px;
    }
    #PageTop {
      opacity: 1;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
}

@media print, screen and (min-width: 768px) and (max-width: 1080px) {
  #HeaderMenu ul {
    li {
      &:not(.menu07) {
        margin-left: 15px !important;
      }
      &.menu07 a {
        padding: 7px 8px 8px;
        span { font-size: 13px; }
      }
      a {
        font-size: 18px;
      }
    }
  }
}

@media print, screen and (min-width: 768px) and (max-width: 1000px) {
  .innerBasic {
    padding-left: 15px;
    padding-right: 15px;
  }
  #TopicPath {
    right: 15px;
  }
}

/*#################### SP ####################*/
@media screen and (max-width: 767px) {
  body {
    padding-top: 80px;
    scroll-padding-top: 80px;
  }
  p {
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .pc_display,.disp_pc {
    display: none;
  }
  .sp_display,.disp_sp {
    display: block;
  }
  .sp_display_inline {
    display: inline;
  }
  /*####################　枠設定　####################*/
  .innerBasic {
    padding: 0 20px;
    margin: 0 auto;
  }
  /*####################　ヘッダ　####################*/
  #Header {
    height: 80px;
    .headerInner {
      width: 100%;
      height: 100%;
      justify-content: left;
      align-items: flex-start;
      padding: 14px 20px;
    }
    h1 {
      a {
        width: 85px;
        height: 18px;
      }
    }
    #HeaderMenu {
      display: none;
    }
    &:before {
      height: 108px;
    }
  }
  .active #Header {
    h1 a {
      background-image: url(../img/header/logo_bk.png) !important;
    }
    &:before {
      display: none !important;
    }
  }
  /*####################　グローバルナビ　####################*/
  #GlobalNav {
    display:none;
  }
  #HeaderMenuSp {
    position: fixed;
    top: -200%;
    left: 0;
    z-index: 51;
    width: 100%;
    height: 100vh;
    background-color: #F7F7F7;
    transition: top 0.5s;
    .menuSpInner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 80px 20px 39px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        width: 100%;
        max-width: 367px;
        height: 135px;
        background: url(../img/header/text_01.png) no-repeat left top;
        background-size: 100% auto;
        position: absolute;
        top: 119px;
        left: 54%;
        transform: translateX(-50%);
        z-index: -1;
      }
      ul {
        li {
          border-bottom: 1px solid #BABABA;
          &:last-of-type {
            border-bottom-width: 0;
          }
          a {
            display: block;
            font-family: $font_basic01;
            font-weight: bold;
            font-size: 25px;
            color: #151515;
            text-align: left;
            text-decoration: none;
            text-transform: capitalize;
            padding: 22px 0;
          }
        }
      }
      .menuBtnClose {
        width: 23px;
        height: 22px;
        background: url(../img/header/icon_02.png) no-repeat left top;
        background-size: 100% auto;
        margin: 27px auto 0;
      }
    }
    &.scroll {
      overflow-y: scroll;
      .menuSpInner {
        height: auto;
      }
    }
  }
  .active #HeaderMenuSp {
    top: 0;
    transition: top 0.5s;
  }

  #Wall {
    display: none;
  }
  /*####################　サブメニュー　####################*/
  #HeaderSub {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 50;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px 20px 20px;
      position: relative;
      li {
        a {
          display: block;
          color: #151515;
          text-align: left;
          text-decoration: none;
        }
        &.menu01 a {
          font-family: $font_basic01;
          font-size: 25px;
          font-weight: bold;
          line-height: 32px;
          text-transform: capitalize;
          padding-right: 30px;
          background: url(../img/contents/icon_02.png) no-repeat right center;
          background-size: 21px auto;
        }
        &.menu02 {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          a {
            width: 26px;
            height: 10px;
            background: url(../img/header/icon_01.png) no-repeat left top;
            background-size: 100% auto;
          }
        }
        &.menu03 a {
          font-size: 14px;
          font-weight: 600;
          line-height: 15px;
          padding-right: 18px;
          background: url(../img/contents/arrow_01_01.png) no-repeat right center;
          background-size: 14px auto;
        }
      }
    }
    &:before {
      content: '';
      width: 100%;
      height: 136px;
      background: linear-gradient(to top, rgba(247, 247, 247, 0.98) 0%, rgba(247, 247, 247, 0.26) 75.37%, rgba(250, 250, 250, 0) 89.66%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      pointer-events: none;
    }
  }
  /*####################　コンテンツ部　####################*/
  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    right: 20px;
    ol li {
      padding-left: 23px;
      font-size: 12px;
      line-height: 21px;
    }
  }

  /*　メインイメージ
  -------------------------------------*/
  #MainImg {
    height: 228px;
    .mainImgInner {
      padding: 39px 20px 0;
      .text {
        .svgBox {
          height: 40px;
          svg {
            width: auto;
            height: 31px;
          }
        }
        h2 {
          font-size: 23px;
          margin: 0;
        }
      }
    }
  }

  /*　個別要素
  -------------------------------------*/
  .btnTypeBg {
    .text {
      p { font-size: 23px; }
    }
  }

  /*　メイン
  -------------------------------------*/
  #Main {
    .titleTypeBold {
      font-size: 23px;
    }
    .titleTypeBgBorder {
      font-size: 18px;
      line-height: 20px;
      padding: 13px 21px 14px 32px;
      margin-bottom: 25px;
    }

    .tableTypeBasic {
      tr {
        &:last-of-type th {
          border-bottom-width: 6px;
        }
      }
      th, td {
        display: block;
        width: 100%;
      }
      th {
        text-align: center;
        border-right-width: 0;
      }
      td {
        font-size: 15px;
        line-height: 25px;
        padding: 15px 16px 16px;
      }
    }

    .listTypeCircle {
      > li {
        font-size: 15px;
        line-height: 20px;
        & + li { margin-top: 10px; }
      }
    }

    .postTypeThumb {
      .post a {
        .content {
          padding: 39px 31px 38px;
          .title {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }

    .svgBox {
      height: 31px;
      margin-bottom: 10px;
      svg {
        width: auto;
        height: 31px;
      }
    }
    .btnBox {
      margin-bottom: -12px;
      > a {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
    .achivBox {
      .box {
        flex-wrap: wrap;
        margin-bottom: 20px;
        .image {
          width: 100%;
          dt {
            width: 100%;
            figure {
              height: 0;
              padding-top: 230px;
              // padding-top: 65.71428571428571%;
              background-position: center top;
            }
          }
          dd {
            flex-direction: column;
            padding: 9px 15px 21px;
            .num {
              line-height: 30px;
              margin-right: 0;
            }
            p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
        .content {
          width: 100%;
          padding: 28px 15px 27px;
          h4 {
            margin: 0 auto;
          }
          table {
            margin-bottom: 29px;
          }
          .btnTypeText {
            margin-right: 14px;
          }
        }
      }
      .box01 {
        .content h4 {
          max-width: 216px;
          margin-bottom: 32px;
        }
      }
      .box02 {
        .content {
          padding-top: 17px;
          h4 {
            max-width: 272px;
            margin-bottom: 25px;
          }
        }
      }
      .box03 {
        .content {
          padding-top: 20px;
          h4 {
            max-width: 245px;
            margin-bottom: 22px;
          }
        }
      }
    }
    .scrollBox {
      width: calc(100% + 20px);
      overflow-y: hidden;
      padding-bottom: 52px;
      &::-webkit-scrollbar {
        width: calc(100% - 20px);
        height: 16px;
      }
    }
    .scrollBox::-webkit-scrollbar-track {
        background-color: #E8E8E8;
        border-radius: 8px;
        margin-right: 20px;
      }
    .scrollBox::-webkit-scrollbar-thumb {
        background-color: #F09933;
        background-clip: padding-box;
        border: 4px solid transparent;
        border-radius: 8px;
      }
  }

  /*　お問い合わせ
  -------------------------------------*/
  #Contact {
    height: 250px;
    a {
      .background {
        background-image: url(../img/contents/contact_bg_02.jpg);
      }
      .innerBasic {
        padding: 0 72px;
      }
      .title {
        margin-bottom: 25px;
        h3 {
          font-size: 20px;
          text-align: left;
          margin-right: 63px;
          &:before {
            font-size: 40px;
            text-indent: 0;
          }
        }
        .btn {
          width: 47px;
          height: 47px;
        }
      }
      p {
        width: 100%;
        max-width: 230px;
        margin: 0 auto;
      }
    }
  }
  /*####################　フッタ　####################*/
  #Footer {
    .footerInner {
      padding: 57px 0 18px;
    }
    .footerMenu {
      padding-top: 39px;
      ul {
        &.mainMenu {
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 250px;
          margin: 0 auto;
          li {
            width: 121px;
            margin-bottom: 30px;
            & + li { margin-left: 0; }
          }
        }
        &.subMenu {
          padding: 0 30px;
          margin-top: 30px;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    #PageTop {
      margin-top: 0;
    }
    #CopyRight {
      height: 122px;
      padding-bottom: 52px;
    }
  }
}


/*end*/